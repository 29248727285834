.card {
  position: relative;
  height: 6rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.nameEn {
  color: #394b59;
  margin: 0 0 0.5rem 0;
}

.namePl {
  color: #394b59;
  margin: 0;
}

.close {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
