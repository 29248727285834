.root {
  & > div:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.title {
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
