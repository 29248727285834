@import "shared";

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.main {
  max-width: $containerMaxWidth;
  min-width: $containerMinWidth;
  padding: $containerPadding;
  margin: 0 auto;
}
