.wrapper {
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-areas:
    "title title"
    "main sidebar";
}

.wrapperNoSidebar {
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas:
    ". title ."
    ". main .";
}

.title {
  grid-area: title;
}

.main {
  grid-area: main;
  padding: 2rem;
  background-color: #f5f8fa;
}

.sidebar {
  grid-area: sidebar;
}
