@import "shared";

.row {
  margin-bottom: 1.5rem;
}

.bio {
  max-width: 100%;
}

.position {
  width: 15rem;
}

.photo {
  width: 24rem;
}
