.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.button:not(:first-child) {
  margin-left: 1rem;
}
