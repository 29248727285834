.card {
  position: relative;
  height: 6rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.agent {
  color: #394b59;
  margin: 0 0 0.5rem 0;
}

.assistants {
  color: #394b59;
  margin: 0;
}
