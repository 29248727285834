@import "shared";

.root {
  height: 8rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.name {
  margin: 0;
  z-index: 10;
  color: #394b59;
}

.imageWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 250px;
}

.image {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -30px;
  width: 220px;
  filter: opacity(30%);
}

.imageMask {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 225px;
  background-image: -webkit-linear-gradient(
    0deg,
    #f5f8fa 35%,
    rgba(#f5f8fa, 0.9) 40%,
    rgba(#f5f8fa, 0.7) 50%,
    rgba(#f5f8fa, 0.5) 65%,
    transparent 95%
  );
}
