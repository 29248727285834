.root {
  padding-bottom: 5rem;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(20rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.search {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 2rem;
  }
}

.title {
  align-self: center;
  color: #293742;
}
