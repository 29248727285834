.row {
  margin-bottom: 1.5rem;
}

.card {
  padding: 3rem;
  background-color: #f5f8fa;
}

.assistants {
  max-width: 50rem;
}
