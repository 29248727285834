.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  & :not(:last-child) {
    margin-right: 0.5rem;
  }
}
