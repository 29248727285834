.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
}

.input {
  width: 25rem;
}
