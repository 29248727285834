@import "shared";

.root {
  height: 8rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  img {
    height: 6.4rem;
    object-fit: contain;
    margin-left: -1rem;
    margin-right: 0.8rem;
  }
}

.title {
  color: #394b59;
  margin-bottom: 0.25em;
}

.titlePl {
  color: #5c7080;
}

.outer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.inner {
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
