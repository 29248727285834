@import "shared";

.row {
  margin-bottom: 1.5rem;
}

.title {
  margin-bottom: 2rem;
}

.name {
  max-width: 30rem;
}

.photo {
  width: 30rem;
}

.bio {
  max-width: 50rem;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
