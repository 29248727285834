@import "shared";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #f5f8fa;
  border: 1px solid #ced9e0;
  margin: 0 0 8px 0;
  border-radius: 3px;
}

.icon {
  color: #ced9e0;
}
