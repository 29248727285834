@import "shared";

.row {
  margin-bottom: 1.5rem;
}

.title {
  max-width: 30rem;
}

.description {
  max-width: 100%;
}

.cover {
  width: 30rem;
}

.publisher {
  width: 20rem;
}

.pubYear {
  width: 10rem;
}

.authors {
  max-width: 50rem;
}

.languages {
  max-width: 50rem;
}
