@import "shared";

.card {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-image: -webkit-linear-gradient(
    330deg,
    rgba($baseColor, 0) 70%,
    rgba($baseColor, 0.1) 30%
  );
}

.link {
  color: rgb(24, 32, 38);
  &:hover {
    color: rgb(24, 32, 38);
    text-decoration: none;
  }
}

.icon {
  svg {
    color: rgba(138, 155, 168, 0.8);
  }
  margin-left: 1.5rem;
}
