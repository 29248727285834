.root {
  min-width: 80rem;
}

.logo {
  img {
    transform: translateY(2px);
  }
}

.button {
  margin-left: 0.75rem;
}

.userButton {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.photo {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.75rem;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #5c7080;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  color: #5c7080;
  margin-right: 0.75rem;
}
