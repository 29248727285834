.card {
  height: 6rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
