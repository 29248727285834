.root {
  height: 7rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.name {
  margin: 0;
  z-index: 10;
  color: #394b59;
}
