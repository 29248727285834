@import "shared";

.title {
  margin-bottom: 2rem;
}

.row {
  margin-bottom: 1rem;
}

.name {
  max-width: 30rem;
}

.description {
  max-width: 50rem;
}

.letters {
  max-width: 8.5rem;
}

.website {
  max-width: 25rem;
}

.territories {
  max-width: 50rem;
}
