.root {
  margin-bottom: 0.5rem;
  border: 1px solid #ced9e0;
  border-radius: 3px;
  overflow: hidden;
  background-color: #f5f8fa;

  img {
    width: 100%;
  }
}
