.card {
  position: relative;
  height: 7rem;
  padding: 1rem;
  background-color: #f5f8fa;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.index {
  position: absolute;
  font-size: 10rem;
  font-weight: bold;
  color: #e1e8ed;
  right: -0.6rem;
  top: -3.1rem;
  letter-spacing: -0.5rem;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 2rem;
}

.name {
  margin: 0;
  z-index: 10;
  color: #394b59;
}

.close {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
